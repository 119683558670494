import {createStore} from 'vuex'
import siteService from '@/services/siteService'


const state_definition = {
    site: {hotels: []},
    siteIsActive: null,
    language: null,
    hotels: [],
    hotel_id: null,
    recommendedHotels: null,
    dataIsReady: false,
    dataHotelsIsLoading: false,
    dataHotelsIsReady: false,
    pois: null,
    filtered_hotels: [],
    filters: {
        ammenities: [],
        distanceFromLat: 0,
        distanceFromLng: 0,
        distanceFromType: '',
        distanceInKm: '',
        endPrice: '',
        finishDate: '',
        hotelName: '',
        isEcoFriendly: false,
        isCityCenter: false,
        isRecommended: false,
        isWalkingDistance: false,
        isMapView: false,
        neighborhoods: [],
        roomType: [],
        rooms: '',
        stars: [],
        startDate: '',
        startPrice: ''
    },
    prebooking: {},
    map_position: {},
    prevUrl: '/',
}

const getters_definition = {
    site: state => {
        return state.site
    },
    siteIsActive: state => {
        return state.siteIsActive
    },
    language: state => {
        return state.site.language
    },
    currency_symbol: state => {
        return state.site.currency_symbol
    },
    recommendedHotels: (_state, getters) => {
        return getters.hotels.filter(hotel => hotel.is_recommended)
    },
    filteredHotels: state => {
        return state.filtered_hotels
    },
    dataIsReady: state => {
        return state.dataIsReady
    },
    dataHotelsIsReady: state => {
        return state.dataHotelsIsReady
    },
    hotels: state => {
        if (state.dataHotelsIsReady)
            return state.hotels

        return state.site.hotels
    },
    hotel: (_state, getters) => hotelId => {
        return getters.hotels.find(hotel => hotel.id === hotelId)
    },
    hotel_id: state => {
        return state.hotel_id
    },
    staticHotel: state => hotelId => {
        return state.site.hotels.find(hotel => hotel.id === hotelId)
    },
    congressName: state => {
        return state.site.stripped_headline
    },
    cityCenterLat: state => {
        return state.site.city_center_location.coordinates[1]
    },
    cityCenterLng: state => {
        return state.site.city_center_location.coordinates[0]
    },
    congressLat: state => {
        return state.site.location.coordinates[1]
    },
    congressLng: state => {
        return state.site.location.coordinates[0]
    },
    congressEmail: state => {
        return state.site.contact_email
    },
    filters: state => {
        return state.filters
    },
    prebooking: state => {
        return state.prebooking
    },
    map_position: state => {
        return state.map_position
    },
    prevUrl: state => {
        return state.prevUrl
    },
}

const mutations = {
    ADD_CONGRESS: (state, congress) => {
        state.congress = congress
    },
    ADD_RECOMMENDED_HOTELS: (state, recommendedHotels) => {
        state.recommendedHotels = recommendedHotels
    },
    SET_SITE: (state, site) => {
        state.site = site
    },
    SET_SITE_IS_ACTIVE: (state, siteIsActive) => {
        state.siteIsActive = siteIsActive
    },
    SET_FILTERED_HOTELS: (state, hotels) => {
        state.filtered_hotels = hotels
    },
    SET_HOTELS: (state, hotels) => {
        state.hotels = hotels
    },
    SET_HOTEL_ID: (state, hotel_id) => {
        state.hotel_id = hotel_id
    },
    DATA_IS_READY: (state, boolean) => {
        state.dataIsReady = boolean
    },
    DATA_HOTELS_IS_READY: (state, boolean) => {
        state.dataHotelsIsReady = boolean
    },
    DATA_HOTELS_IS_LOADING: (state, boolean) => {
        state.dataHotelsIsLoading = boolean
    },
    POIS: (state, pois) => {
        state.pois = pois
    },
    ADD_FILTER: (state, filter) => {
        state.filters[filter.name] = filter.value
    },
    SET_PREBOOKING: (state, prebooking) => {
        state.prebooking = prebooking
    },
    SET_MAP_POSITION: (state, map_position) => {
        state.map_position.zoom = map_position.zoom
        state.map_position.position = map_position.position
    },
    SET_PREV_URL: (state, prevUrl) => {
        state.prevUrl = prevUrl
    },
}

const actions = {
    addFilters: (context, filter) => {
        context.commit('ADD_FILTER', filter)
    },
    fetchData: (context) => {
        return siteService.getSiteSettings()
            .then(result => {
                context.commit('SET_SITE', result)
                context.commit('DATA_IS_READY', true)

                if ('active' in result)
                    context.commit('SET_SITE_IS_ACTIVE', result.active)
            })


    },
    fetchHotels: (context) => {
        if (!context.state.hotels.length && !context.state.dataHotelsIsLoading) {
            context.commit('DATA_HOTELS_IS_LOADING', true)
            return siteService.getSiteHotels()
                .then(result => {
                    context.commit('SET_HOTELS', result)
                })
                .catch(() => {
                    context.commit('SET_HOTELS', [])
                })
                .finally(() => {
                    context.commit('DATA_HOTELS_IS_READY', true)
                    context.commit('DATA_HOTELS_IS_LOADING', false)
                })
        }
    }
}

export default createStore({
    state: state_definition,
    mutations,
    actions,
    getters: getters_definition,
})
